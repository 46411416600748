input, textarea {
  outline: none !important;
}

* {
  outline: none !important;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background: linear-gradient(180deg, #01150b, #082c19, #0b4025, #184d2d, #2b522d, #3e592c, #596932, #646f33, #787839, #79793a, #ac924f, #e7b16a, #eebe80, #f8d09c, #ffdcaf, #ffdcaf);
  margin-bottom: 290px !important;

  &.maintenance-mode {
    margin-bottom: 0 !important;
  }

  @media (max-width: $breakpoint-991) {
    margin-bottom: 0 !important;
  }
}

body.no-scroll {
  overflow: hidden;
}

body, html {
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
}

.h-85px {
  height: 85px;
}

h1, h2, h3 {

}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  color: inherit;
}

textarea {
  resize: none;
}

.w-100 {
  width: 100%;
}

.text-center-991 {
  @media (max-width: $breakpoint-991) {
    text-align: center;
  }
}

.hide-767 {
  @media (max-width: $breakpoint-767) {
    display: none;
  }
}

.show-767 {
  display: none;

  @media (max-width: $breakpoint-767) {
    display: inline-block;
  }
}


.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.bdr-15 {
  border-radius: 15px;
}

.w-90 {
  width: 90%;
}

.bottom-5 {
  bottom: 5%;
}

.bdr-21 {
  border-radius: 21px;
}

.bdr-10 {
  border-radius: 21px;
}

p {
  margin-bottom: 0;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px;
}

.h-150px {
  height: 150px !important;
}

.bdr-40 {
  border-radius: 40px;
}

.obj-contain {
  object-fit: contain !important;
}

.h-70px {
  height: 70px;
}

.h-100px {
  height: 100px !important;
}

.h-800px {
  height: 800px !important;
}

.h-300px {
  height: 300px !important;
}
.h-500px {
  height: 500px !important;
}
.h-charity-content{
  height: 300px;
  min-height: 300px;
  overflow-y: auto;
}

.h-750px {
  height: 750px !important;
}

.h-700px {
  height: 700px !important;
}

.list-style-none {
  list-style: none;
}

.list-with-arrow li {
  padding-left: 30px;
  position: relative;
}

.list-with-arrow {
  list-style: none;
  padding: 0;
}

.list-with-arrow li::before {
  content: "→";
  font-family: 'slick';
  left: 0;
  position: absolute;
  color: #C5AF74
}

.h-50px {
  height: 50px !important;
}

.max-width-140px {
  max-width: 140px !important;
}