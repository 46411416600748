$badge-pill-padding-x: 1em;
$badge-padding-y: 0.5em;
$badge-padding-x: 1em;

$text-align: left;

@import "modules/fonts";
@import "node_modules/bootstrap/scss/bootstrap.scss";

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";

@import "modules/variables";
@import "modules/mixins";
@import "modules/helpers";
@import "modules/spacing";
@import "modules/grid-5-cols";

@import "partials/accordion";
@import "partials/bg";
@import "partials/border";
@import "partials/btn";
@import "partials/card";
@import "partials/carousel";
@import "partials/splash_screen";
@import "partials/text";
@import "partials/footer";
@import "partials/form";
@import "partials/images";
@import "partials/label";
@import "partials/list";
@import "partials/pagination";
@import "partials/media_queries";
@import "partials/navigation";
@import "partials/section";
@import "partials/separator";
@import "partials/select";
@import "partials/topbar";
@import "partials/datepicker";

@import "partials/video";

@import "pages/preview_panel";
@import "pages/ifa";

@import "pages/general";
@import "pages/home";
@import "pages/partner";
@import "pages/cart";