.navbar {
  --bs-navbar-nav-link-padding-x: 0.75rem;

  .logo {
    width: 150px;
    @media (max-width: $breakpoint-991) {
      width: 120px;
    }
    @media (max-width: 300px) {
      width: 80px;
    }
  }

  .nav-link {
    color: #FFF !important;
    font-family: $font-main-text-regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    position: relative;

    &:hover {
      text-decoration: none;

      &:after {
        width: 76%;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 3px;
      background: #ffffff;
      transition: width 0.3s ease-in-out;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    &.cart-link {
      .cart-items-count {
        background-color: #F6F3D8;
        color: #0E492D;
        font-family: $font-secondary-bold;
        font-size: 14px;
        font-weight: 700;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        top: -8px;
        right: -10px;
      }
    }
  }

  .navbar-toggler {
    border: unset;
  }

  .navbar-toggler:focus {
    box-shadow: unset;
  }

  .active {
    position: relative;
    transition: color 0.3s ease;
  }

  .active::after {
    width: 76%;
  }

  .nav-link:focus-visible {
    box-shadow: unset;
  }

  .select-lang {
    min-width: 100%;
    border-radius: 15px;
    text-align: center;
    font-family: $font-main-text-regular;
    font-size: 13px;
  }

}

.scrolled-navbar {
  background: linear-gradient(to bottom, #01120A, #01140B, #02150B, #051E11, #082817);
}

.scrolled-navbar .container {
  background-color: transparent;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: $white;
  color: #0F2725;
  border-color: transparent;
  letter-spacing: 1.5px
}

.nav-tabs {
  border: unset;
}

.nav-tabs .nav-link {
  border-color: transparent;
  background-color: #DFE2C7;
  color: #8BA589;
  padding: 22px 30px;
  margin-right: 10px;
  border-radius: 16px 16px 0px 0px;
  font-family: $font-main-title-bold;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 1.5px
}

.tab-content {
  background: $white;
  border-radius: 0px 0px 16px 16px;
}

.tab-pane {
  padding: 20px;
  color: #626262;
  font-family: $font-main-text-medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-transform: capitalize;

}

.dropdown-item {
  transition: 0.2ms ease-in;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: $primary !important;
  background: unset !important;
  font-weight: bolder;
  text-decoration: underline !important;
}